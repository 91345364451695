define('arctic-community-sensor-web/models/location', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    encodingType: _emberData.default.attr(),
    location: _emberData.default.attr(),

    things: _emberData.default.hasMany('thing')
  });
});