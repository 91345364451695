define('arctic-community-sensor-web/components/openlayers-map', ['exports', 'ol/Map', 'ol/View', 'ol/proj'], function (exports, _Map, _View, _proj) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    lat: null,
    lon: null,
    // Maximum zoom level permitted by the map, regardless of tile layer
    // settings.
    maxZoom: 19,
    projection: 'EPSG:3857',
    zoom: null,

    map: null,

    init() {
      this._super(...arguments);
    },

    /*
      The HTML element is ready and we can create the map.
     */
    didInsertElement() {
      this._super(...arguments);

      let projection = this.get('projection');

      let map = new _Map.default({
        target: this.element.id,
        layers: [],
        view: new _View.default({
          center: (0, _proj.transform)([this.get('lon'), this.get('lat')], 'EPSG:4326', projection),
          maxZoom: this.get('maxZoom'),
          projection: projection,
          zoom: this.get('zoom')
        })
      });

      this.set('map', map);
      // Pass an event to any sub-components waiting for the Map element
      // to be ready.
      this.trigger('ready', map);
    }
  });
});