define('arctic-community-sensor-web/components/live-panel-wind', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['panel'],
    classNameBindings: ['isActivePanel'],

    activeDatastream: null,
    speed: null,
    direction: null,

    didReceiveAttrs() {
      this._super(...arguments);
      if (this.get('activeDatastream') == this.get('speed')) {
        this.set('isActivePanel', true);
      } else {
        this.set('isActivePanel', false);
      }
    }
  });
});