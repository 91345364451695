define('arctic-community-sensor-web/components/openlayers-map/feature', ['exports', 'ol/Feature', 'ol/format/GeoJSON'], function (exports, _Feature, _GeoJSON) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    parentComponent: null,

    // GeoJSON representation of the feature
    geoJSON: null,

    // Handler for when the feature is "clicked"
    onClick: null,

    // Title for the feature, to be displayed in a popup
    title: null,

    init() {
      this._super(...arguments);
      this.get('parentComponent').on('ready', source => {
        let parser = new _GeoJSON.default();

        // An OpenLayers Feature can have arbitrary properties that can
        // be accessed by handlers.
        let feature = new _Feature.default({
          geometry: parser.readGeometry(this.get('geoJSON'), {
            featureProjection: "EPSG:3573"
          }),
          onClick: this.get('onClick'),
          title: this.get('title')
        });

        source.addFeature(feature);
      });
    }
  });
});