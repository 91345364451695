define('arctic-community-sensor-web/controllers/observed-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sortedModel: Ember.computed('model.[]', function () {
      return this.get('model').sortBy('name');
    })
  });
});