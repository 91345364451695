define('arctic-community-sensor-web/models/observation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    phenomenonTime: _emberData.default.attr(),
    result: _emberData.default.attr(),
    resultTime: _emberData.default.attr(),

    datastream: _emberData.default.belongsTo('datastream')
  });
});