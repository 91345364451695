define('arctic-community-sensor-web/models/datastream', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    unitOfMeasurement: _emberData.default.attr(),
    observationType: _emberData.default.attr(),

    observations: _emberData.default.hasMany('observation', { $top: 10 }),
    observedProperty: _emberData.default.belongsTo('observed-property'),
    thing: _emberData.default.belongsTo('thing'),

    /*
      Execute a custom query to SensorThings API to return up to 50 
      Observations from this Datastream for the past 24 hours, based on
      the `phenomenonTime`.
    */
    recentObservations() {
      let oneDayAgo = new Date(new Date() - 86400 * 1000);

      return this.get('store').query('observation', {
        parent: {
          id: this.get('id'),
          modelName: 'datastream'
        },
        $top: 50,
        $orderby: 'phenomenonTime desc',
        $filter: 'phenomenonTime ge ' + oneDayAgo.toISOString()
      });
    },

    // Computed Properties

    /*
      Sort Observations in ascending phenomenonTime order
    */
    sortedObservations: Ember.computed('observations.[]', function () {
      return this.get('observations').then(observations => {
        return observations.sortBy('phenomenonTime');
      });
    }),

    // Select the newest Observation in this Datastream, and return a
    // PromiseObject.
    lastObservation: Ember.computed('sortedObservations', function () {
      let p = new Promise(resolve => {
        this.get('sortedObservations').then(observations => {
          resolve(observations.get('lastObject'));
        });
      });

      return _emberData.default.PromiseObject.create({
        promise: p
      });
    })
  });
});