define('arctic-community-sensor-web/components/openlayers-map/tile-layer', ['exports', 'ol/layer/Tile', 'ol/source/XYZ'], function (exports, _Tile, _XYZ) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    parentComponent: null,

    // The attribution string for this layer. Will be displayed when this
    // layer is active.
    attribution: null,
    // Bounding extent for the layer, which will not be rendered outside
    // this extent. Use `ol/extent` object.
    extent: null,
    // Maximum zoom level supported by the tile server.
    maxZoom: 19,
    opacity: 1.0,
    // Projection of the tiles, defaults to Web Mercator (EPSG:3857).
    projection: 'EPSG:3857',
    // The URL for the XYZ source for this layer.
    url: null,
    visible: true,

    init() {
      this._super(...arguments);
      this.get('parentComponent').on('ready', map => {
        let tileLayer = new _Tile.default({
          extent: this.get('extent'),
          opacity: this.get('opacity'),
          source: new _XYZ.default({
            attributions: this.get('attribution'),
            maxZoom: this.get('maxZoom'),
            projection: this.get('projection'),
            url: this.get('url')
          }),
          visible: this.get('visible')
        });

        map.addLayer(tileLayer);
      });
    }
  });
});