define('arctic-community-sensor-web/models/observed-property', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    definition: _emberData.default.attr(),

    datastreams: _emberData.default.hasMany('datastream')
  });
});