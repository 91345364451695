define('arctic-community-sensor-web/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      let store = this.get('store');
      return store.query('Thing', {
        $expand: 'Locations'
      });
    }
  });
});