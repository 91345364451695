define('arctic-community-sensor-web/models/thing', ['exports', 'ember-data', 'javascript-time-ago', 'javascript-time-ago/locale/en'], function (exports, _emberData, _javascriptTimeAgo, _en) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  _javascriptTimeAgo.default.addLocale(_en.default);

  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    properties: _emberData.default.attr(),

    datastreams: _emberData.default.hasMany('datastream', {
      $expand: 'ObservedProperty'
    }),
    locations: _emberData.default.hasMany('location', { limit: 5 }),

    // Retrieve the Datastreams for this Thing and find the Datastream
    // with an Observed Property name matching `propertyName`.
    getDatastreamByProperty(propertyName) {
      let p = new Promise((resolve, reject) => {
        this.get('datastreams').then(datastreams => {
          let ds = datastreams.find(datastream => {
            return datastream.get('observedProperty.name') === propertyName;
          });

          resolve(ds);
        }, err => {
          reject(err);
        });
      });

      return _emberData.default.PromiseObject.create({ promise: p });
    },

    lastLocation: Ember.computed('locations', function () {
      return this.get('locations.firstObject');
    }),

    /*
      Retrieve all the cached Datastreams, then the latest Observation for
      each Datastream. Return the most recent Observation phenomenonTime.
    */
    latestObservation: Ember.computed('primaryDatastreams', function () {
      let p = new Promise((resolve, reject) => {
        this.get('primaryDatastreams').then(datastreams => {
          // As the Datastreams may not be ready yet, we have to use
          // Promise.all to resolve them before continuing.
          Promise.all(datastreams.map(datastream => {
            // Ignore unavailable Datastreams
            if (datastream) {
              return datastream.get('lastObservation');
            }
          })).then(lastObservations => {
            if (lastObservations === undefined || lastObservations.length === 0) {
              reject();
            } else {
              let sorted = lastObservations.filter(obs => {
                return obs !== undefined;
              }).sort((x, y) => {
                return new Date(x.get('phenomenonTime')) - new Date(y.get('phenomenonTime'));
              });

              if (sorted.length > 0) {
                resolve(sorted[sorted.length - 1]);
              }
            }
          }, err => {
            reject(err);
          });
        }, err => {
          reject(err);
        });
      });

      return _emberData.default.PromiseObject.create({
        promise: p
      });
    }),

    timeOfLastUpdate: Ember.computed('latestObservation', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.get('latestObservation').then(observation => {
          return observation.get('phenomenonTime');
        })
      });
    }),

    timeSinceLastUpdate: Ember.computed('timeOfLastUpdate', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.get('timeOfLastUpdate').then(time => {
          const timeAgo = new _javascriptTimeAgo.default('en-CA');
          let date = new Date(time);
          return timeAgo.format(date);
        })
      });
    }),

    /*
      Shortcut property for the primary datastreams
    */

    primaryDatastreams: Ember.computed('datastreams.[]', function () {
      let p = Promise.all([this.get('airPressure'), this.get('airTemperature'), this.get('relativeHumidity'), this.get('visibility'), this.get('windDirection'), this.get('windSpeed')]);

      return _emberData.default.PromiseObject.create({
        promise: p
      });
    }),

    airPressure: Ember.computed('datastreams.[]', function () {
      return this.getDatastreamByProperty('Station Pressure');
    }),

    airTemperature: Ember.computed('datastreams.[]', function () {
      return this.getDatastreamByProperty('Air Temperature');
    }),

    relativeHumidity: Ember.computed('datastreams.[]', function () {
      return this.getDatastreamByProperty('Relative Humidity');
    }),

    visibility: Ember.computed('datastreams.[]', function () {
      return this.getDatastreamByProperty('Visibility');
    }),

    windDirection: Ember.computed('datastreams.[]', function () {
      return this.getDatastreamByProperty('Vectoral average 10 meter wind direction over past 10 minutes');
    }),

    windSpeed: Ember.computed('datastreams.[]', function () {
      return this.getDatastreamByProperty('Average 10 meter wind speed over past 10 minutes');
    })
  });
});