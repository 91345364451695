define('arctic-community-sensor-web/components/openlayers-map/vector-layer', ['exports', 'ol/layer/Vector', 'ol/source', 'ol/style'], function (exports, _Vector, _source, _style) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    parentComponent: null,

    // Array of OpenLayers features for the VectorSource
    features: null,

    init() {
      this._super(...arguments);
      this.get('parentComponent').on('ready', map => {
        // Styling for a point feature
        let pointStyle = new _style.Style({
          image: new _style.Circle({
            radius: 5,
            fill: new _style.Fill({ color: 'white' }),
            stroke: new _style.Stroke({ color: 'blue', width: 1 })
          })
        });

        // Vector Source will support adding features after component has
        // been created.
        let source = new _source.Vector({
          features: this.get('features')
        });

        let layer = new _Vector.default({
          source: source,
          style: pointStyle
        });

        map.addLayer(layer);

        // Emit an event with the source for any sub-components that are
        // waiting for the layer to be on the map.
        this.trigger('ready', source);
      });
    }
  });
});