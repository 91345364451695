define('arctic-community-sensor-web/routes/observed-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model() {
      let model = 'observed-property';
      let store = this.get('store');
      return store.findAll(model);
    }
  });
});