define('arctic-community-sensor-web/controllers/index', ['exports', 'ol/proj/proj4', 'proj4'], function (exports, _proj, _proj2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Register the custom projection for ArcticWebMap here.
  // This must be defined by Proj4 then registered by OpenLayers *before*
  // the OpenLayers map component is created.
  _proj2.default.defs("EPSG:3573", "+proj=laea +lat_0=90 +lon_0=-100 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs");
  (0, _proj.register)(_proj2.default);

  exports.default = Ember.Controller.extend({
    // Default location for the map.
    // This will automatically be transformed from latitude/longitude (aka
    // EPSG:4326) to the appropriate projection for the map.
    lat: 65,
    lon: -100,
    zoom: 4,

    activeDatastream: null,
    activeStation: null,

    actions: {
      selectStation(stn) {
        let lat = stn.get('lastLocation.location.coordinates')[1];
        let lng = stn.get('lastLocation.location.coordinates')[0];

        this.set('activeStation', stn);
        this.set('activeDatastream', stn.get('airTemperature'));
        this.set('temperatureDatastream', stn.get('airTemperature'));
        this.set('forecastLinkForStation', "https://spotwx.com/products/grib_index.php?model=gem_reg_10km&lat=" + lat + "&lon=" + lng);
      }
    }
  });
});