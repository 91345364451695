define('arctic-community-sensor-web/components/last-update-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['panel'],

    station: null,
    displayDateOffset: true,

    actions: {
      switchDate() {
        this.set('displayDateOffset', !this.get('displayDateOffset'));
      }
    }
  });
});