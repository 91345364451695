define('arctic-community-sensor-web/helpers/format-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDate = formatDate;


  /*
    Convert an ISO8601 Date String (which may contain fractional seconds)
    to a human-readable Date string in the user's locale.
   */
  function formatDate([proxy]) {
    let date = new Date(proxy.content);
    return date.toLocaleString();
  }

  exports.default = Ember.Helper.helper(formatDate);
});